import request from '../request'


// 根据币种分页查询广告
export function pageByUnit(data) {
	return request({
		url: '/otc/advertise/page-by-unit',
		method: 'POST',
		data: data
	})
}


// 个人所有广告
export function advertiseAll(data) {
	return request({
		url: '/otc/advertise/all',
		method: 'POST',
		data: data
	})
}


// 广告详情
export function advertiseDetail(data) {
	return request({
		url: '/otc/advertise/detail',
		method: 'POST',
		data: data
	})
}

// 获取取广告的币种
export function coinAll(data) {
	return request({
		url: '/otc/coin/all',
		method: 'POST',
		data: data
	})
}

// 删除广告
export function advertiseDel(data) {
	return request({
		url: '/otc/advertise/delete',
		method: 'POST',
		data: data
	})
}

// 广告上架
export function advertiseOn(data) {
	return request({
		url: '/otc/advertise/on/shelves',
		method: 'POST',
		data: data
	})
}

// 广告下架
export function advertiseOff(data) {
	return request({
		url: '/otc/advertise/off/shelves',
		method: 'POST',
		data: data
	})
}


// 修改广告
export function advertiseUpdate(data) {
	return request({
		url: '/otc/advertise/update',
		method: 'POST',
		data: data
	})
}

// 创建广告
export function advertiseCreate(data) {
	return request({
		url: '/otc/advertise/create',
		method: 'POST',
		data: data
	})
}

// 我的订单
export function orderSelf(data) {
	return request({
		url: '/otc/order/self',
		method: 'POST',
		data: data
	})
}


// //获取id广告信息
export function orderPre(data) {
	return request({
		url: '/otc/order/pre',
		method: 'POST',
		data: data
	})
}


// 我的订单
export function orderBuy(data) {
	return request({
		url: '/otc/order/buy',
		method: 'POST',
		data: data
	})
}


// 我的订单
export function orderSell(data) {
	return request({
		url: '/otc/order/sell',
		method: 'POST',
		data: data
	})
}